import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import HomeHeader from './HomeHeader';
import HomeBody from './HomeBody';
import { Grid, Typography } from '@mui/material';


export default function Home() {
  return (
    <Container maxWidth="xs" sx={{ padding: 0.1 }}>
      <Box sx={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12}>
            <HomeHeader />
          </Grid>
          <Grid item xs={12}>
            <HomeBody />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign:'center', width: "100%", bottom: 0,borderBottomRightRadius: 18, borderBottomLeftRadius: 18, background: `linear-gradient(9deg, rgba(67,183,230,1) 0%, rgba(67,183,230,1) 40%, rgba(164,220,245,1) 68%);`, fontFamily: 'Helvetica, Arial, sans-serif', color: '#171A5F'}}>
          <Typography>Liên hệ WVIVU</Typography>
          <Typography>CÔNG TY TNHH TM DV QUỐC HỒ</Typography>
          <Typography>Số 07-09,đường 30/04, P.3,Tp Sóc Trăng</Typography>
          <Typography>MST: <span style={{color:'#ffff'}}>2200810541</span></Typography>
          <Typography>Tổng đài hỗ trợ: <a style={{textDecoration:'none'}} href="tel:0975181851"><span style={{color:'#ffff'}}>0975.1818.51</span></a></Typography>
      </Box>
    </Container>
  );
}
