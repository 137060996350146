import { Box, Button, ButtonGroup, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import {Service} from './services/Service';

export default function Boat04() {
  const [open, setOpen] = React.useState(false);
  let component1 = [];
  component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id='GBV01' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V01</Button><Button id='GBV02' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V02</Button><Button id='GBV03' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V03</Button><Button id='GBV04' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V04</Button><Button id='GBV05' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V05</Button><Button id='GBV06' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V06</Button></ButtonGroup>);
  component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id='GBV07' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V07</Button><Button  id='GBV08' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V08</Button><Button id='GBV09' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V09</Button><Button id='GBV10' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V10</Button><Button id='GBV11' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V11</Button><Button id='GBV12' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V12</Button></ButtonGroup>);
  component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id='GBV13'onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V13</Button><Button id='GBV14' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V14</Button><Button id='GBV15' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V15</Button><Button id='GBV16' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V16</Button><Button id='GBV17' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V17</Button><Button id='GBV18' onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V18</Button></ButtonGroup>);
  
  let component2 = [];
  for(var i = 3;i<23;i++) {
    if(i == 13)
    {
      continue;
    }
    else
    {
      component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBA0'+i : 'GBA'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>A{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBB0'+i : 'GBB'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>B{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBC0'+i : 'GBC'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>C{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBD0'+i : 'GBD'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>D{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  let component3 = [];
  for(var i = 1;i<25;i++) {
    if(i >= 12 && i <= 14)
    {
      component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else if(i == 24)
    {
      component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button id={i.toString().length == 1 ? 'GBF0'+i : 'GBF'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>F{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBG0'+i : 'GBG'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>G{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBH0'+i : 'GBH'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>H{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBI0'+i : 'GBI'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>I{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBJ0'+i : 'GBJ'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>J{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
    else 
    {
      component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBE0'+i : 'GBE'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>E{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBF0'+i : 'GBF'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>F{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBG0'+i : 'GBG'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>G{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBH0'+i : 'GBH'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>H{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBI0'+i : 'GBI'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>I{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBJ0'+i : 'GBJ'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>J{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  let component4 = [];
  for(var i = 3;i<23;i++) {
    if(i == 13)
    {
      continue;
    }
    else
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBK0'+i : 'GBK'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>K{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBL0'+i : 'GBL'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>L{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBM0'+i : 'GBM'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>M{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBN0'+i : 'GBN'+i} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>N{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  return (
    <Grid container sx={{ width:'1000px' }}>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '900px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '3px' }}>
          <ButtonGroup variant="outlined" sx={{margin: '2px'}}><Button sx={{backgroundColor: 'yellow', marginRight:'1px'}}>Ghế VIP</Button>,<Button sx={{marginRight:'1px'}}>Ghế trống</Button>,<Button sx={{backgroundColor: '#59E096', color: 'white'}}>Ghế đã xuất vé</Button>,<Button sx={{backgroundColor: '#38D8EB', color: '#F6FAFD'}}>Ghế đang chọn</Button></ButtonGroup>
        </Box> 
      </Grid>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '900px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '35px' }}>
          {component1}
        </Box> 
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '100px', marginBottom: '20px' }}>
          {component2}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '400px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component3}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '100px'}}>
          {component4}
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        message="Bạn đã chọn đủ ghế, Vui lòng thực hiện bước tiếp theo!"
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
}

