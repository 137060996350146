import { Box, Button, ButtonGroup, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import {Service} from './services/Service';

export default function Boat11Up2() {
  const [open, setOpen] = React.useState(false);

  let component1 = [];
  for(var i = 53; i< 78; i++) {
    if(i  < 55)
    {
      component1.push(<ButtonGroup variant="outlined"><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button id={'GBDB'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DB{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDC'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DC{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDD'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DD{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
    else
    {
      component1.push(<ButtonGroup variant="outlined"><Button id={'GBDA'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>DA{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDB'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DB{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDC'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DC{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDD'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DD{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  let component2 = [];
  for(var i = 50; i < 77; i++) {
      if(i >= 58 && i <= 62)
      {
        component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBDE'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>DE{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDF'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DF{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDG'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DG{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
  }

  let component3 = [];
  for(var i = 50;i<77;i++) {
      if(i >= 58 && i <= 62)
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBDH'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>DH{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDI'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DI{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDJ'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DJ{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
  }

  let component4 = [];
  for(var i = 53; i< 78; i++) {
    if(i  < 55)
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBDK'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DK{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDL'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DL{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDM'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DM{i.toString().length == 1 ? '0'+i : i}</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBDK'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>DK{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDL'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DL{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDM'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DM{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBDN'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>DN{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  return (
    <Grid container sx={{ width:'1100px' }}>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '900px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '3px' }}>
          <ButtonGroup variant="outlined" sx={{margin: '2px'}}><Button sx={{backgroundColor: 'yellow', marginRight:'1px'}}>Ghế VIP</Button>,<Button sx={{marginRight:'1px'}}>Ghế trống</Button>,<Button sx={{backgroundColor: '#59E096', color: 'white'}}>Ghế đã xuất vé</Button>,<Button sx={{backgroundColor: '#38D8EB', color: '#F6FAFD'}}>Ghế đang chọn</Button></ButtonGroup>
        </Box> 
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '135px' }}>
          {component1}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '250px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component2}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '250px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component3}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '135px', marginBottom: '20px' }}>
          {component4}
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        message="Bạn đã chọn đủ ghế, Vui lòng thực hiện bước tiếp theo!"
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
}