import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Home from './Home';
import OrderPassenger from './OrderPassenger';
import OrderPassengerNext from './OrderPassengerNext';
import OrderTripFrom from './OrderTripFrom';
import OrderTripTo from './OrderTripTo';
import OrderBill from './OrderBill';
import OrderAction from './OrderAction';
import OrderCustomer from './OrderCustomer';
import OrderPaymentConfirm from './OrderPaymentConfirm';
import OrderPaymentMethod from './OrderPaymentMethod';
import OrderPaymentTotal from './OrderPaymentTotal';
import OrderSearch from './OrderSearch';
import OrderZaloCallback from './OrderZaloCallback';
import Price from './Price';
import History from './History';
import Contact from './Contact';
import OrderChair from './OrderChair';
import OrderChairTo from './OrderChairTo';
import ConditionReturn from './ConditionReturn';
import ConditionSecurity from './ConditionSecurity';
import ConditionTransfer from './ConditionTransfer';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/OrderSearch",
    element: <OrderSearch />
  },
  {
    path: "/OrderTripFrom",
    element: <OrderTripFrom />
  },
  {
    path: "/OrderTripTo",
    element: <OrderTripTo />
  },
  {
    path: "/OrderPassenger",
    element: <OrderPassenger />
  },
  {
    path: "/OrderPassengerNext",
    element: <OrderPassengerNext />
  },
  {
    path: "/OrderBill",
    element: <OrderBill />
  },
  {
    path: "/OrderAction",
    element: <OrderAction />
  },
  {
    path: "/OrderCustomer",
    element: <OrderCustomer />
  },
  {
    path: "/OrderPaymentConfirm",
    element: <OrderPaymentConfirm />
  },
  {
    path: "/OrderPaymentMethod",
    element: <OrderPaymentMethod />
  },
  {
    path: "/OrderPaymentTotal",
    element: <OrderPaymentTotal />
  },
  {
    path: "/Price",
    element: <Price />
  },
  {
    path: "/History",
    element: <History />
  },
  {
    path: "/Contact",
    element: <Contact />
  },
  {
    path: "/OrderChair",
    element: <OrderChair />
  },
  {
    path: "/OrderChairTo",
    element: <OrderChairTo />
  },
  {
    path: "/ConditionReturn",
    element: <ConditionReturn />
  },
  {
    path: "/ConditionSecurity",
    element: <ConditionSecurity />
  },
  {
    path: "/ConditionTransfer",
    element: <ConditionTransfer />
  },
  {
    path: "/OrderZaloCallback",
    element: <OrderZaloCallback />
  },
]);

root.render(
  <RouterProvider router={router} />
);
