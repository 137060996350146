import forge from 'node-forge';

export class RsaService {
  private privateKey: string;
  private publicKey: string;
  

  constructor() {
    this.privateKey = `-----BEGIN RSA PRIVATE KEY-----
    -----END RSA PRIVATE KEY-----`;
    this.publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIIBCgKCAQEAl7lnlHusIiwSIq9komz6B/EsaTtkoEHt72pDBn/qQVeiX6jwhaHA
    I+7pOD6Cd/Tn3TMa7Eh/o0JggK/+gUTtVRYBY9bwmnYrSDUIdGFSnWI5dpd18ziN
    dni8w4UxEOfIziuynITbgiJklyZj7fW4r6ulIMcXxBd76l9j9IjBe51Yy2IBqu/J
    QvbUpFS/zS5DRy2d/YIkhy7NUYfmVC64QdykwheGo9fHKHefX29OndAOdy0FK/Fz
    D3skG0EMZu/A31NMNBhTJG2LX0wxhmIUNOxZ653i1Pcde+MTvaRpmyR+nXyrkQAE
    SDi5qMqhk9TfvOL2l/F7NIN3Bu6IDS1ZVwIDAQAB
    -----END RSA PUBLIC KEY-----`;
    
  }

  encryptMessage(plaintext: string): string {
    const p = forge.pki.publicKeyFromPem(this.publicKey);
    const encrypted = p.encrypt(plaintext, 'RSA-OAEP');
    const encryptedBase64 = forge.util.encode64(encrypted);
    return encryptedBase64;
  };

  decryptMessage(encryptedBase64: string): string {
    const p = forge.pki.privateKeyFromPem(this.privateKey);
    const plaintext = p.decrypt(encryptedBase64, 'RSA-OAEP');
    return plaintext;
  };

  generateAESKey() {
    return forge.random.getBytesSync(32); // 32 bytes = 256 bits
  };

  encryptDataWithAES(data: string, key: string) {
    const iv = forge.random.getBytesSync(16); // Initialization vector
    const cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(data, 'utf8'));
    cipher.finish();
    return {
      iv: forge.util.encode64(iv),
      encryptedData: forge.util.encode64(cipher.output.getBytes())
    };
  }
  
  // Hàm để mã hóa khóa AES bằng RSA OAEP
  encryptAESKeyWithRSA(aesKey: string, publicKey: string) {
    const rsa = forge.pki.publicKeyFromPem(publicKey);
    const encryptedAESKey = rsa.encrypt(aesKey, 'RSA-OAEP', {
      md: forge.md.sha256.create()
    });
    return forge.util.encode64(encryptedAESKey);
  }

  encryptLargeData(data: string) {
    const aesKey = this.generateAESKey();
    const encryptedData = this.encryptDataWithAES(data, aesKey);
    const encryptedAESKey = this.encryptAESKeyWithRSA(aesKey, this.publicKey);
  
    return {
      encryptedAESKey: encryptedAESKey,
      iv: encryptedData.iv,
      encryptedData: encryptedData.encryptedData
    };
  };
}