import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import MainMenu from './MainMenu';
import {Service} from './services/Service';

const BodyPage = styled(Paper)(({ theme }) => ({
backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
...theme.typography.body2,
color: theme.palette.text.secondary,
}));

export default function HomeBody() {
  const [horizontalScroll, setHorizontalScroll] = useState(0);
  const [dataOptions, setDataOptions] = useState([]);
  let isLoad = false;
  useEffect(() => {

    const imageListEl = document.querySelector("#imageList");
    if(!isLoad)
    {
        Service.GetBanner().then(res => {
            isLoad = true;
            const updatedOptions = res.map((row: {id: string; image: string; title: string; subTitle: string; subTitle2: string}) => {
                return {id:row.id, image: row.image, title: row.title, subTitle: row.subTitle, subTitle2: row.subTitle2 };
            });
            setDataOptions(updatedOptions)
        })
    }
    imageListEl?.addEventListener(
      "scroll",
      () => {
        setHorizontalScroll(imageListEl?.scrollLeft);
      },
      { passive: true }
    );
  }, [horizontalScroll]);
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.has('loginToken'))
  {
    const loginToken = urlParams.get('loginToken')
    sessionStorage.setItem('loginToken', loginToken || '');
  }

return (
    <BodyPage elevation={0}>
        <MainMenu/>
        <Typography sx={{ height: '100%', borderBottomRightRadius: 16,borderBottomLeftRadius: 16, backgroundColor: '#F7FBFE' }}>
            <ListSubheader component="div" sx={{ color:'#000000', lineHeight: '20px', fontWeight: 'bold', paddingLeft: '5px', fontSize: '18px' }}>Địa điểm nổi tiếng</ListSubheader>
            <ImageList
                id="imageList"
                sx={{
                    width: "100%",
                    scrollbarWidth: "none",
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fill,minmax(10em, 1fr)) !important",
                    gridAutoColumns: "minmax(10em, 1fr)",
                    overflowX: "scroll",
                    maskImage:
                    horizontalScroll > 0
                        ? [
                            "linear-gradient(to left, black calc(100% - 48px), transparent 100%)",
                            "linear-gradient(to right, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%)",
                        ]
                        : [""]
                }}
                gap={10}
                variant="standard"
                rowHeight="auto"
            >
                {dataOptions.map((item: {id: string, image: string; title: string; subTitle: string; subTitle2: string }) => (
                    <ImageListItem key={item.id}>
                    <img
                        src={`${item.image}&w=248&h=220&fit=crop&auto=format`}
                        srcSet={`${item.image}&w=248&h=220&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        style={{ borderRadius: 10, height: '120px' }}
                    />
                    <ImageListItemBar sx={{ fontWeight: 'bold' }}
                        title={item.title}
                        subtitle={<><span>{item.subTitle}</span><br></br><span style={{color:'red'}}>{item.subTitle2}</span></>}
                        position="below"
                    />
                    </ImageListItem>
                ))}
            </ImageList>
        </Typography>
    </BodyPage>
  );
}