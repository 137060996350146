import { Box, Button, ButtonGroup, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import {Service} from './services/Service';


export default function Boat11Down() {
  const [open, setOpen] = React.useState(false);

  let component1 = [];
  for(var i = 3; i< 34; i++) {
    if(i  < 9)
    {
      component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button id={'GBVB'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VB{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVC'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VC{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
    else if(i >= 13 && i <= 19)
    {
      component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else
    {
      component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBVA'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>VA{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVB'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VB{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVC'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VC{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  let component2 = [];
  for(var i = 1; i < 34; i++) {
      if(i >= 16 && i <= 21)
      {
        component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBVD'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>VD{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVE'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VE{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVF'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VF{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVG'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VG{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
  }

  let component3 = [];
  for(var i = 3;i<33;i++) {
      if(i  < 9)
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBVH'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VH{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVI'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VI{i.toString().length == 1 ? '0'+i : i}</Button><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else if(i >= 15 && i <= 19)
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBVH'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>VH{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVI'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VI{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBVJ'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>VJ{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
  }

  return (
    <Grid container sx={{ width:'850px' }}>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '800px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '3px' }}>
          <ButtonGroup variant="outlined" sx={{margin: '2px'}}><Button sx={{backgroundColor: 'yellow', marginRight:'1px'}}>Ghế VIP</Button>,<Button sx={{marginRight:'1px'}}>Ghế trống</Button>,<Button sx={{backgroundColor: '#59E096', color: 'white'}}>Ghế đã xuất vé</Button>,<Button sx={{backgroundColor: '#38D8EB', color: '#F6FAFD'}}>Ghế đang chọn</Button></ButtonGroup>
        </Box> 
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '100px' }}>
          {component1}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component2}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '100px' }}>
          {component3}
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        message="Bạn đã chọn đủ ghế, Vui lòng thực hiện bước tiếp theo!"
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
}