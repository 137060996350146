import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Backdrop, Box, CircularProgress, Link, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {Service} from './services/Service';

function createData(
  id: string,
  label: string,
  data: object
) {
  return {id, label, data };
}

export default function HistoryBody() {
  const [open, setOpen] = React.useState(false);
  const [dataOptions, setDataOptions] = useState([]);
  const [dataDetails, setDataDetails] = useState();
  const [dataTickets, setDataTickets] = useState([]);
  const statusText = ['Chưa thanh toán', 'Đã thanh toán', 'Đã xuất vé', 'Đã hủy'];
  
  const fetchRequest = useCallback((param: any) => {

    let htmlBody: any = [];
    let htmlPassengerBody: any = [];
    htmlBody.push(<div>
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Thông tin đơn hàng</Typography>&nbsp;&nbsp;&nbsp;
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            + Trạng thái đơn hàng: {statusText[param.data.status]}
          </Typography>
          <Typography>
            + Tổng tiền: {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(param.data.total)}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>Thông tin khách khách hàng</Typography>&nbsp;&nbsp;&nbsp;
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          + Người đặt vé: {param.data.bookName}
        </Typography>
        <Typography>
          + Điện thoại: {param.data.bookPhone}
        </Typography>
        <Typography>
          + Địa chỉ: {param.data.cusAddress}
        </Typography>
        <Typography>
          + Mã số thuế: {param.data.cusTaxNumber}
        </Typography>
      </AccordionDetails>
    </Accordion>
    </div>);
    for (const key in param.data.ticket) {
      htmlPassengerBody.push(<AccordionDetails>
        <Typography>
          + Họ tên: {param.data.ticket[key].passengerName}
        </Typography>
        <Typography>
          + CCCD/Passport: {param.data.ticket[key].passengerIdentify}
        </Typography>
        <Typography>
          + Nơi sinh: {param.data.ticket[key].passengerBirthPlace}
        </Typography>
        <Typography>
          + Năm sinh: {param.data.ticket[key].PassengerYear}
        </Typography>
        {
          param.data.ticket[key].images.length > 0 &&
        <Typography>
          + Vé: <Link target='blank' href={param.data.ticket[key].images[0]} underline="hover"> Click vào xem vé</Link>
        </Typography>
        }
      </AccordionDetails>);
    }
    setDataDetails(htmlBody);
    setDataTickets(htmlPassengerBody);
  }, [])

  useEffect(() => {
    setOpen(true);
    let CustomerPhone = localStorage.getItem('CustomerPhone') || '';
    Service.GetHistory(CustomerPhone).then(res => {
          //console.log(res)
          const updatedOptions = res.map((row: {id: string, createdAt: string }) => {
            var d = new Date(row.createdAt.toString());
            return createData(row.id, d.toLocaleString("vi-VN"), row);
          });
          setDataOptions(updatedOptions);
          setOpen(false);
      })
  }, [])

  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3'}}>
      <Box sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
        <Autocomplete
            disablePortal
            blurOnSelect="touch"
            options={dataOptions}
            onChange={(event, newValue) => {
              if(newValue != '')
              {
                if(newValue == null) return;
                const tmp = JSON.parse(JSON.stringify(newValue)) || [];
                fetchRequest(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} name="Chọn" label="Vui lòng ngày đặt vé"/>}
          />
      
      {dataDetails}
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>Thông tin hành khách</Typography>&nbsp;&nbsp;&nbsp;
      </AccordionSummary>
      {dataTickets}
    </Accordion>  
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </Box>
    </Box>
  );
}