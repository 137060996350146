import { Backdrop, Box, Button, CircularProgress, Grid, InputLabel, ListSubheader, TableRow } from '@mui/material';
import * as React from 'react';
import { Paper, styled } from '@mui/material';
import OrderFooter from './OrderFooter'
import { useNavigate } from 'react-router-dom';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { IRequestCreateCart } from './services/Type';
import { IPassenger } from './services/Type';
import {Service} from './services/Service';
import { useEffect, useState } from 'react';

const NotePaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  border:'none'
}));

export default function OrderPaymentTotalBody() {


  const navigate = useNavigate();
  const [dataTotal, setDataTotal] = useState();
  const [dataCode, setDataCode] = useState();
  const [open, setOpen] = React.useState(false);
  let CustomerName = localStorage.getItem('CustomerName') || "";
  let CustomerPhone = localStorage.getItem('CustomerPhone') || "";
  let CustomerEmail = localStorage.getItem('CustomerEmail') || "";
  let CustomerBillName = localStorage.getItem('CustomerBillName') || "";
  let CustomerTax = localStorage.getItem('CustomerTax') || "";
  let CustomerCompany = localStorage.getItem('CustomerCompany') || "";
  let CustomerAddress = localStorage.getItem('CustomerAddress') || "";
  let route = sessionStorage.getItem('route') || '{}';
  const objRoute = JSON.parse(route);
  let fromId = Number(sessionStorage.getItem('fromId')) || 0;

  const rawData: IRequestCreateCart = {
    bookName: CustomerName,
    bookPhone: CustomerPhone,
    bookEmail: CustomerEmail,
    cusName: CustomerBillName,
    cusAddress: CustomerAddress,
    cusTaxNumber: CustomerTax,
    cusCompany: CustomerCompany,
    voyages: [
      {
        routeId: objRoute['id'],
        voyageId: fromId,
        passengers: []
      }
    ]
  }

  let amount = Number(sessionStorage.getItem('amount')) || 0;
  const way = sessionStorage.getItem('way');
  
  for (let i = 0; i < amount; i++) {
    const ticketInfo = sessionStorage.getItem(fromId+'Chair'+(i+1)) || '';
    const arrayTicketInfo = ticketInfo.split(";");
    const passengerData: IPassenger =
    {
      identify: localStorage.getItem('PassengerCCCDPassport'+(i+1)) || '',
      name: localStorage.getItem('PassengerName'+(i+1)) || '',
      birthPlace: localStorage.getItem('PassengerPlace'+(i+1)) || '',
      birthday: localStorage.getItem('PassengerYear'+(i+1)) || '',
      nationId: 1,
      ticketTypeId: 1,
      seatId: Number(arrayTicketInfo[1] || 0),
      seatName: arrayTicketInfo[0] || '',
      ticketClass: arrayTicketInfo[2] || ''
    }
    rawData.voyages[0].passengers.push(passengerData);
  }

  if(way == 'twoway')
  {
    let toId = Number(sessionStorage.getItem('toId')) || 0;
    const voyageTo =
    {
      routeId: objRoute['id'],
      voyageId: toId,
      passengers: []
    }
    rawData.voyages.push(voyageTo);
    for (let i = 0; i < amount; i++) {
      const ticketInfo = sessionStorage.getItem(toId+'ChairTo'+(i+1)) || '';
      const arrayTicketInfo = ticketInfo.split(";");
      const passengerData: IPassenger =
      {
        identify: localStorage.getItem('PassengerCCCDPassport'+(i+1)) || '',
        name: localStorage.getItem('PassengerName'+(i+1)) || '',
        birthPlace: localStorage.getItem('PassengerPlace'+(i+1)) || '',
        birthday: localStorage.getItem('PassengerYear'+(i+1)) || '',
        nationId: 1,
        ticketTypeId: 1,
        seatId: Number(arrayTicketInfo[1]) || 0,
        seatName: arrayTicketInfo[0] || '',
        ticketClass: arrayTicketInfo[2] || ''
      }
      rawData.voyages[1].passengers.push(passengerData);
    }
  }
  useEffect(() => {
    setOpen(true);
    if(amount == 0) navigate("/");
    Service.CreateCart(rawData).then(res => {
      console.log(res);
      var tmp = res == null ? 0 : res.total;
      tmp = tmp.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
      setDataTotal(tmp);
      setDataCode(res == null ? '' : res.transactionCode);
      sessionStorage.clear();
      sessionStorage.setItem('OrderID',res.cartId);
      sessionStorage.setItem('OrderCode',res.transactionCode)
      setOpen(false);
    }); 

  }, [])
  
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <LocalAtmOutlinedIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>THÔNG TIN THANH TOÁN</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box 
      component="form"
      sx={{'& .MuiTextField-root': { m: 1 }, backgroundColor:'#ffffff' }}
      noValidate 
      autoComplete="off"
      >
        <div>
          <Grid container>
            <Grid item xs={5} sx={{paddingLeft: '15px', marginTop:1, color:'#0D11B2'}}>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{fontWeight: 'bold', marginTop:1, color:'black'}}>Mã đơn hàng</InputLabel>
            </Grid>
            <Grid item xs={6} sx={{textAlign:'left', marginTop:1}}>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{fontWeight: 'bold', marginTop:1, color: 'red'}}>{dataCode}</InputLabel>
            </Grid>
            <Grid item xs={5} sx={{paddingLeft: '15px', marginTop:1, color:'#0D11B2'}}>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{fontWeight: 'bold', marginTop:1, color:'black'}}>Tổng thanh toán</InputLabel>
            </Grid>
            <Grid item xs={6} sx={{textAlign:'left', marginTop:1}}>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{fontWeight: 'bold', marginTop:1, color: 'red'}}>{dataTotal}</InputLabel>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="section" sx={{ textAlign: 'center', paddingTop:2,paddingBottom:2, backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        <Button variant="contained" onClick={()=>navigate("/OrderPaymentMethod")}>
        Thanh toán
        </Button>
      </Box>
      <OrderFooter/>
    </Box>
  );
}