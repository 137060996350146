import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Header from './Header';
import ContactBody from './ContactBody';
import { Grid } from '@mui/material';

export default function Contact() {
  return (
    <Container maxWidth="xs" sx={{ padding: 0.1 }}>
      <Box sx={{ width: "100%",height:"100%" }}>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
              <ContactBody />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
