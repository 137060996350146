import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Header from './Header';
import OrderPaymentTotalBody from './OrderPaymentTotalBody';

export default function OrderPaymentTotal() {
  return (
    <Container maxWidth="xs" sx={{ padding: 0.1 }}>
      <Box sx={{ width: "100%",height:"100%" }}>
        <Header />
        <OrderPaymentTotalBody />
      </Box>
    </Container>
  );
}
