import { Box, Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListSubheader, OutlinedInput } from '@mui/material';
import * as React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import OrderFooter from './OrderFooter'
import MailIcon from '@mui/icons-material/Mail';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

function check(id: string, value: string) {
  const item = document.getElementById(id);
  if(value.trim() == '')
  {
    return false;
  }
  else
  {
    return true;
  }
}

function validate(id: string, value: string) {
  const item = document.getElementById(id);
  if(value.trim() == '')
  {
    if (item) {
      item.style.display = 'block';
    }
    return false;
  }
  else
  {
    if (item) {
      item.style.display = 'none';
    }
    return true;
  }
}

export default function OrderActionBody() {
  const navigate = useNavigate();
  sessionStorage.setItem('page','OrderAction');
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <PersonIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>CHỌN PHƯƠNG THỨC NHẬP THÔNG TIN HÀNH KHÁCH</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <div style={{
        backgroundColor:'#ffffff',
        paddingTop: '20px',
        textAlign: 'center',
        lineHeight: 5
        }}>
        <Button variant="outlined" size="large" onClick={() => {navigate("/OrderPassenger")}}>
          Cách 1: Chụp CCCD/Hộ chiếu
        </Button>
        <Button variant="outlined" size="large" onClick={() => {navigate("/OrderPassenger")}}>
          Cách 2: Nhập thông tin thủ công
        </Button>
      </div>
      <Box component="section" sx={{ textAlign: 'left', paddingleft: '10px', backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        <div style={{width: '90%', marginLeft: '15px', marginBottom: '10px'}}>Gợi ý: Chụp CCCD/Hộ chiếu là phương thức nhanh và chính xác nhất</div>
      </Box>
      <OrderFooter/>
    </Box>
  );
}