import { Box, Button, ButtonGroup, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import {Service} from './services/Service';

export default function Boat06Down() {
  const [open, setOpen] = React.useState(false);

  let component1 = [];
  for(var i = 11; i< 26; i++) {
    if(i==13)
    {
      continue;
    }
    else
    {
      component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBA'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>A{i}</Button>,<Button id={'GBB'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>B{i}</Button>,<Button id={'GBC'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>C{i}</Button></ButtonGroup>);
    }
  }

  let component2 = [];
  for(var i = 11;i<29;i++) {
    if(i==13)
    {
      continue;
    }
    else if(i == 15 || i == 16)
    {
      component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else
    {
      component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBD'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>D{i}</Button>,<Button id={'GBE'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>E{i}</Button></ButtonGroup>);
    }
  }

  let component3 = [];
  for(var i = 11;i<29;i++) {
      if(i==13)
      {
        continue;
      }
      else if(i == 15 || i == 16)
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBG'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>G{i}</Button>,<Button id={'GBH'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>H{i}</Button></ButtonGroup>);
      }
  }

  let component4 = [];
  for(var i = 11;i<26;i++) {
    if(i==13)
    {
      continue;
    }
    else
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBK'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>K{i}</Button>,<Button id={'GBL'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>L{i}</Button>,<Button id={'GBM'+(i)} onClick={ (e) =>  {Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>M{i}</Button></ButtonGroup>);
    }
  }

  return (
    <Grid container sx={{ width:'750px' }}>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '700'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '3px' }}>
          <ButtonGroup variant="outlined" sx={{margin: '2px'}}><Button sx={{backgroundColor: 'yellow', marginRight:'1px'}}>Ghế VIP</Button>,<Button sx={{marginRight:'1px'}}>Ghế trống</Button>,<Button sx={{backgroundColor: '#59E096', color: 'white'}}>Ghế đã xuất vé</Button>,<Button sx={{backgroundColor: '#38D8EB', color: '#F6FAFD'}}>Ghế đang chọn</Button></ButtonGroup>
        </Box> 
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '190px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px' }}>
          {component1}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '170px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component2}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '170px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component3}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '190px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px'}}>
          {component4}
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        message="Bạn đã chọn đủ ghế, Vui lòng thực hiện bước tiếp theo!"
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
}