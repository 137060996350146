import { Backdrop, Box, Button, CircularProgress, FormControlLabel, Grid, InputLabel, ListSubheader, Radio, RadioGroup, TableRow } from '@mui/material';
import * as React from 'react';
import { Paper, styled } from '@mui/material';
import OrderFooter from './OrderFooter'
import { useNavigate } from 'react-router-dom';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import {Service} from './services/Service';

function setClicked(orderId: string): void {
  Service.GetZaloURL(orderId,"https://wvivu.com/OrderZaloCallback").then(res => {
    if(res)
    {
      if(res.data) window.location.replace(res.data.url || '');
    }
  })
}

export default function OrderPaymentMethodBody() {
  const navigate = useNavigate();
  
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <LocalAtmOutlinedIcon/>
          </Grid>
          <Grid item xs={10} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>CHỌN HÌNH THỨC THANH TOÁN</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box 
      component="form"
      sx={{'& .MuiTextField-root': { m: 1 }, backgroundColor:'#ffffff' }}
      noValidate 
      autoComplete="off"
      >
        <div>
          <Grid container>
            <Grid xs={12} sx={{paddingTop: '5px',paddingLeft: '20px',height: window.innerHeight - 400, maxHeight: '450px'}}>
              <RadioGroup row>
                <FormControlLabel value="zalopay" control={<Radio onClick={() => setClicked(sessionStorage.getItem('OrderID') || '')} />} label={<img src="./static/images/Zalopay_logo-ai.png" alt="ZaloPay" style={{width: "80px"}} />} sx={{ backgroundColor: '#EBEDFE', WebkitBorderRadius: 16, width: '330px', margin: '5px'}}/>
                <FormControlLabel value="banking" control={<Radio onClick={() => navigate('/OrderPaymentConfirm') }/>} label="Chuyển khoản ngân hàng" sx={{ backgroundColor: '#EBEDFE', WebkitBorderRadius: 16, width: '330px', margin: '5px'}}/>
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Box component="section" sx={{ textAlign: 'center', paddingTop:2,paddingBottom:2, backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        
      </Box>
      <OrderFooter/>
    </Box>
  );
}