import { Alert, Box, Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListSubheader, OutlinedInput } from '@mui/material';
import * as React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import OrderFooter from './OrderFooter'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

function validate(id: string, value: string) {
  const item = document.getElementById(id);
  if(value.trim() == '')
  {
    if (item) {
      item.style.display = 'block';
    }
    return false;
  }
  else
  {
    if (item) {
      item.style.display = 'none';
    }
    return true;
  }
}

function validatePhone(id: string, value: string) {
  const item = document.getElementById(id);
  if(value.trim() == '' || value.length != 10)
  {
    if (item) {
      item.style.display = 'block';
    }
    return false;
  }
  else
  {
    if (item) {
      item.style.display = 'none';
    }
    return true;
  }
}

export default function OrderCustomerBody() {
  const navigate = useNavigate();
  let CustomerName = localStorage.getItem('CustomerName') == null ? '' : localStorage.getItem('CustomerName');
  let CustomerPhone = localStorage.getItem('CustomerPhone') == null ? '' : localStorage.getItem('CustomerPhone');
  let CustomerEmail = localStorage.getItem('CustomerEmail') == null ? '' : localStorage.getItem('CustomerEmail');
  let CheckCustomerName = CustomerName != '' ? true : false;
  let CheckCustomerPhone = CustomerPhone != '' ? true : false;
  CheckCustomerPhone = CustomerPhone?.length != 10 ? false : true;
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
        <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <PersonIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>THÔNG TIN NGƯỜI ĐẶT VÉ</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1 },
          backgroundColor:'#ffffff'
        }}
        noValidate
        autoComplete="off"
      >
      <div>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }} required>
          <InputLabel htmlFor="outlined-adornment-amount">Họ tên người đặt vé</InputLabel>
          <OutlinedInput
            defaultValue={CustomerName}
            onChange={e => 
              {
                CheckCustomerName = validate('CustomerName',e.target.value.toString());
                localStorage.setItem('CustomerName', e.target.value);
              }
            }
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"><PersonIcon/></InputAdornment>}
            label="Họ tên người đặt vé"
          />
          <FormHelperText id="CustomerName" error sx={{display:'none'}}>Vui lòng nhập "Họ tên người đặt vé"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }} required>
          <InputLabel htmlFor="outlined-adornment-amount">Điện thoại</InputLabel>
          <OutlinedInput
            defaultValue={CustomerPhone}
            inputProps={{ maxLength: 10, type:'number'}}
            onChange={e => 
              {
                CheckCustomerPhone = validatePhone('CustomerPhone',e.target.value.toString());
                localStorage.setItem('CustomerPhone', e.target.value);
              }
            }
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"><PhoneIphoneIcon/></InputAdornment>}
            label="Điện thoại"
          />
          <FormHelperText id="CustomerPhone" error sx={{display:'none'}}>Vui lòng nhập "Điện thoại người đặt vé"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
          <OutlinedInput
            defaultValue={CustomerEmail}
            onChange={e => localStorage.setItem('CustomerEmail', e.target.value)}
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"><MailIcon/></InputAdornment>}
            label="Email"
          />
        </FormControl>
      </div>
      </Box>
      <Box component="section" sx={{ textAlign: 'right', paddingRight: '10px', backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        <Button startIcon={<NavigateNextIcon  />} onClick={()=>
            {
              if(!CheckCustomerName)
              {
                const item = document.getElementById('CustomerName');
                if (item) {
                  item.style.display = 'block';
                }
              }
              if(!CheckCustomerPhone)
              {
                const item = document.getElementById('CustomerPhone');
                if (item) {
                  item.style.display = 'block';
                }
              }
              if(CheckCustomerPhone && CheckCustomerName)
              {
                navigate("/OrderBill");
              }
            }
          }>
          Tiếp theo
        </Button>
      </Box>
      <OrderFooter/>
    </Box>
  );
}