import { Alert, Backdrop, Box, CircularProgress, Grid, ListSubheader, TableContainer, TableHead } from '@mui/material';
import * as React from 'react';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import OrderFooter from './OrderFooter'
import { useNavigate } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useCallback, useEffect, useState } from 'react';
import {Service} from './services/Service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  id: string,
  boatId: string,
  boatName: string,
  departDate: string,
  departTime: string,
  remainingNo: string
) {
  return {id, boatId, boatName, departDate, departTime, remainingNo };
}

function getRouteTo(routeId: number) {
  switch(routeId) {
    case 11:
      return 12;
    case 12:
      return 11;
    case 3:
      return 4;
    case 4:
      return 3;
    case 1:
      return 2;
    case 2:
      return 1;
    case 29:
      return 30;
    case 30:
      return 29;
    case 25:
      return 26;
    case 26:
      return 25;
    case 13:
      return 14;
    case 14:
      return 13;
    default:
      // code block
  }
}

function formatDate(date: string) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [year, month, day].join('-');
}

export default function OrderTripBody() {
  const navigate = useNavigate();
  const [dataOptions, setDataOptions] = useState([]);
  const [open, setOpen] = React.useState(false);
  let route = sessionStorage.getItem('route') || '{}';
  const objRoute = JSON.parse(route);
  let title = "HÀNH TRÌNH ĐI"
  let routeId = objRoute['id'];
  if(location.pathname == '/OrderTripTo')
  {
    title = "HÀNH TRÌNH VỀ"
    routeId = getRouteTo(Number(routeId));
  }
  useEffect(() => {
    
    let searchDate = '';
    if(location.pathname == '/OrderTripFrom')
    {
      searchDate = sessionStorage.getItem('fromDate') || '';
    }
    else{
      searchDate = sessionStorage.getItem('toDate') || '';
    }
    searchDate = formatDate(searchDate);
    setOpen(true);
    Service.SearchRoutes(String(routeId),searchDate).then(res => {
          //console.log(res)
          const updatedOptions = res.map((row: {id: string, boatId: string; boatName: string; departDate: string; departTime: string; remainingNo: string}) => {
            return createData(row.id, row.boatId, row.boatName, new Date(row.departDate).toLocaleDateString('vi-VN'), row.departTime.toString(), row.remainingNo );
          });
          setDataOptions(updatedOptions);
          console.log(updatedOptions)
          setOpen(false);
      })
  }, [])

  sessionStorage.setItem('page',location.pathname);
  return (
    <Box sx={{ width: '100%' , height: '100%' , backgroundColor: '#29ACE3'}}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container sx={{backgroundColor:'#ffffff'}}>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <RoomOutlinedIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left',backgroundColor:'#ffffff'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>{title}</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box component="section" sx={{height: '100%', maxHeight: '450px', width: '100%', borderBottomRightRadius: 18, borderBottomLeftRadius: 18 }}>
      <TableContainer component={Paper} sx={{height: window.innerHeight - 350, maxHeight: '450px', backgroundColor: '#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18 }}>
        <Table stickyHeader sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Tên tàu</TableCell>
              <TableCell align="center">Ngày</TableCell>
              <TableCell align="right">Giờ</TableCell>
              <TableCell align="right">Ghế trống</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataOptions.map((row: {id: string, boatId: string; boatName: string; departDate: string; departTime: string; remainingNo: string; }) => (
              <StyledTableRow key={row.id} onClick={(newRowSelectionModel) => {
                  if(location.pathname == '/OrderTripFrom')
                  {
                    sessionStorage.setItem('fromBoatId', row.boatId);
                    sessionStorage.setItem('fromId', row.id);
                  }
                  else
                  {
                    sessionStorage.setItem('toBoatId', row.boatId);
                    sessionStorage.setItem('toId', row.id);
                  }
                  
                  const way = sessionStorage.getItem('way');
                  if(way == 'oneway' || location.pathname == '/OrderTripTo')
                  {
                    navigate("/OrderCustomer");
                  }
                  else{
                    navigate("/OrderTripTo");
                  }
                }}>
                <StyledTableCell component="th" scope="row">
                  {row.boatName}
                </StyledTableCell>
                <StyledTableCell align="center">{row.departDate}</StyledTableCell>
                <StyledTableCell align="right">{row.departTime}</StyledTableCell>
                <StyledTableCell align="right">{row.remainingNo}</StyledTableCell>
              </StyledTableRow>
            ))
            }
            
          </TableBody>
        </Table>
        {dataOptions.length === 0 ? (
        <Alert variant="outlined" severity="info">
            Không có chuyến trong thời gian này
        </Alert>
        ) : (
          ''
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </TableContainer>
      </Box>
      <OrderFooter/>
    </Box>
  );
}