import { Backdrop, Box, Button, CircularProgress, Grid, InputLabel, ListSubheader, TableRow } from '@mui/material';
import * as React from 'react';
import { Paper, styled } from '@mui/material';
import OrderFooter from './OrderFooter'
import { useNavigate } from 'react-router-dom';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { IRequestCreateCart } from './services/Type';
import { IPassenger } from './services/Type';
import {Service} from './services/Service';
import { useEffect, useState } from 'react';

const NotePaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  border:'none'
}));

export default function OrderPaymentConfirmBody() {

  const [seconds, setSeconds] = React.useState(60);
  const [minutes, setMinutes] = React.useState(4);
  const navigate = useNavigate();
  
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }

    if (seconds == 0 && minutes > 0) {
      setTimeout(() => {setSeconds(59); setMinutes(minutes - 1)}, 1000);
    }
  }, [seconds, minutes])

  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <LocalAtmOutlinedIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>THÔNG TIN THANH TOÁN</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box 
      component="form"
      sx={{'& .MuiTextField-root': { m: 1 }, backgroundColor:'#ffffff' }}
      noValidate 
      autoComplete="off"
      >
        <div>
          <Grid container>
            <Grid item xs={11} sx={{textAlign:'left'}}>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px', fontWeight: 'bold', marginTop:1}}>Thông tin chuyển khoản:</InputLabel>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px', marginTop:1}}>+ Ngân hàng ACB</InputLabel>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px'}}>+ CONG TY TNHH TMDV QUOC HO</InputLabel>
              <Grid container>
                <Grid item xs={5} sx={{textAlign:'left'}}>
                  <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px'}}>+ Số tài khoản: </InputLabel> 
                </Grid>
                <Grid item xs={4} sx={{textAlign:'left'}}>
                <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px'}} id="copyBank">83832288</InputLabel> 
                </Grid>
                <Grid item xs={2} sx={{textAlign:'left'}}>
                <Button variant="contained" onClick={()=> 
                  {
                    const tmp = document.getElementById('copyBank'); 
                    if(tmp) 
                    { 
                      navigator.clipboard.writeText(tmp.innerText);
                    }
                  }}>Copy</Button>
                </Grid>
              </Grid>
              <Grid container sx={{marginTop:'5px'}}>
                <Grid item xs={5} sx={{textAlign:'left'}}>
                  <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px'}}>+ Nội dung: </InputLabel> 
                </Grid>
                <Grid item xs={4} sx={{textAlign:'left'}}>
                <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px'}} id="copyContent">{sessionStorage.getItem('OrderCode')||''}</InputLabel> 
                </Grid>
                <Grid item xs={2} sx={{textAlign:'left'}}>
                <Button variant="contained" onClick={()=> 
                  {
                    const tmp = document.getElementById('copyContent'); 
                    if(tmp) 
                    { 
                      navigator.clipboard.writeText(tmp.innerText);
                    }
                  }}>Copy</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4.5} sx={{paddingLeft: '15px', marginTop:1}}>
              <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', fontWeight: 'bold', marginTop:1, fontStyle:'italic'}}>*Lưu ý:</InputLabel>
            </Grid>
            <Grid item xs={11} sx={{textAlign:'left'}}>
              <NotePaper variant="outlined" sx={{paddingLeft: '5px'}}>+ Sau khi chuyển khoản thành công, hình ảnh vé sẽ được cập nhật sau 15-30 phút ở phần "Đơn hàng"</NotePaper>
            </Grid>
            <Grid item xs={11} sx={{textAlign:'left'}}>
              <NotePaper variant="outlined" sx={{paddingLeft: '5px'}}>+ Vui lòng chuyển khoản trong thời gian : <span style={{color:'red'}}>{minutes}:{seconds}</span> để đảm bảo vị trí đã chọn</NotePaper>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="section" sx={{ textAlign: 'center', paddingTop:2,paddingBottom:2, backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        <Button variant="contained" onClick={()=>navigate("/History")}>
         Xem đơn hàng
        </Button>
      </Box>
      <OrderFooter/>
    </Box>
  );
}