import * as React from 'react';
import Typography from '@mui/material/Typography';
import AppsIcon from '@mui/icons-material/Apps';
import Grid from "@mui/material/Unstable_Grid2";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { IconButton, Paper, styled } from '@mui/material';
import SeachBox from './SeachBox';
import { useNavigate } from 'react-router-dom';
import SubMenu from './SubMenu';

const Header = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  height: '210px',
}));

export default function HomeHeader() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <Header elevation={0}>
        <Typography sx={{  height: '220px', WebkitBorderRadius: 16,textAlign: 'center',background: `linear-gradient(9deg, rgba(67,183,230,1) 0%, rgba(67,183,230,1) 40%, rgba(164,220,245,1) 68%);`}}>
          <Grid container>
            <Grid sx={{paddingTop: 2,paddingLeft: 2}}>
              <IconButton sx={{ color: 'white',backgroundColor: 'grey' }} onClick={toggleDrawer(true)}>
                <AppsIcon/>
              </IconButton>
            </Grid>
            <Grid xs={9} sx={{height: '95px'}}>
              <Box component="img" sx={{height: '90px',width: '90px', marginTop: '8px'}} src="./static/images/logo.png"/>
            </Grid>
          </Grid>
          <Box display="flex" alignItems="left" sx={{fontWeight: 'normal',fontFamily: 'Times New Roman',fontSize: 18,marginLeft: 1}}>
            Cùng WVIVU
          </Box>
          <Box display="flex" alignItems="left" sx={{fontWeight: 'bold',fontFamily: 'Times New Roman',fontSize: 24,marginLeft: 1, color: '#171A5F'}}>
            Khám phá vùng trời biển đảo
          </Box>
          <Box display="flex" alignItems="left" sx={{fontWeight: 'bold',fontFamily: 'Times New Roman',fontSize: 24,marginLeft: 1, color: '#171A5F'}}>
          <SeachBox/>
          </Box>
        </Typography>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <SubMenu/>
        </Drawer>
    </Header>
  );
}