import { Box, Button, ButtonGroup, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import {Service} from './services/Service';

export default function Boat09Up() {
  const [open, setOpen] = React.useState(false);
  let component1 = [];
  component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button sx={{backgroundColor: 'yellow'}} id='GBV01' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V01</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV02' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V02</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV03' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V03</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV04' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V04</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV05' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V05</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV06' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V06</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV07' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V07</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV08' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V08</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV09' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V09</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV10' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V10</Button></ButtonGroup>);
  component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button sx={{backgroundColor: 'yellow'}} id='GBV11' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V11</Button><Button  id='GBV12' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V12</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV13' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V13</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV14' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V14</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV15' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V15</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV16' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V16</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV17' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V17</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV18' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V18</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV19' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V19</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV20' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V20</Button></ButtonGroup>);
  component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button sx={{backgroundColor: 'yellow'}} id='GBV21'onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V21</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV22' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V22</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV23' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V23</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV24' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V24</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV25' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V25</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV26' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V26</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV27' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V27</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV28' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V28</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV29' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V29</Button><Button sx={{backgroundColor: 'yellow'}} id='GBV30' onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>V30</Button></ButtonGroup>);
  
  let component2 = [];
  for(var i = 1;i<15;i++) {
    if(i == 13)
    {
      component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else
    {
      component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBA0'+i : 'GBA'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>A{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBB0'+i : 'GBB'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>B{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBC0'+i : 'GBC'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>C{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  let component3 = [];
  for(var i = 1;i<16;i++) {
    if(i == 13)
    {
      component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else if(i == 5)
    {
      component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else if(i<5)
    {
      component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBD0'+i : 'GBD'+i} onClick={ (e) => { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>D{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBE0'+i : 'GBE'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>E{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBF0'+i : 'GBF'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>F{i.toString().length == 1 ? '0'+i : i}</Button><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else 
    {
      component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBD0'+i : 'GBD'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>D{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBE0'+i : 'GBE'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>E{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBF0'+i : 'GBF'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>F{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBG0'+i : 'GBG'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>G{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  let component4 = [];
  for(var i = 1;i<16;i++) {
    if(i == 5)
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else if(i == 13)
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else if(i<5)
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button><Button id={i.toString().length == 1 ? 'GBI0'+i : 'GBI'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>I{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBJ0'+i : 'GBJ'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>J{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBK0'+i : 'GBK'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>K{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    } 
    else
    {
      component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBH0'+i : 'GBH'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>H{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBI0'+i : 'GBI'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>I{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBJ0'+i : 'GBJ'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>J{i.toString().length == 1 ? '0'+i : i}</Button><Button id={i.toString().length == 1 ? 'GBK0'+i : 'GBK'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>K{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    } 
  }

  let component5 = [];
  for(var i = 1;i<16;i++) {
    if(i == 15)
    {
      component5.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button id={i.toString().length == 1 ? 'GBM0'+i : 'GBM'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>M{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBN0'+i : 'GBN'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>N{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
    else if(i == 13)
    {
      component5.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else
    {
      component5.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBL0'+i : 'GBL'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>L{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBM0'+i : 'GBM'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>M{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBN0'+i : 'GBN'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>N{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }
  return (
    <Grid container sx={{ width:'1000px' }}>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '900px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '3px' }}>
          <ButtonGroup variant="outlined" sx={{margin: '2px'}}><Button sx={{backgroundColor: 'yellow', marginRight:'1px'}}>Ghế VIP</Button>,<Button sx={{marginRight:'1px'}}>Ghế trống</Button>,<Button sx={{backgroundColor: '#59E096', color: 'white'}}>Ghế đã xuất vé</Button>,<Button sx={{backgroundColor: '#38D8EB', color: '#F6FAFD'}}>Ghế đang chọn</Button></ButtonGroup>
        </Box> 
      </Grid>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '800px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '35px' }}>
          {component1}
        </Box> 
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '210px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component2}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component3}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px'}}>
          {component4}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '210px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '35px' }}>
          {component5}
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        message="Bạn đã chọn đủ ghế, Vui lòng thực hiện bước tiếp theo!"
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
}

