import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import OrderFooter from "./OrderFooter";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Service} from './services/Service';
import { useCallback, useEffect } from "react";
import Boat09Up from "./Boat09Up";
import Boat09Down from "./Boat09Down";
import Boat07Up from "./Boat07Up";
import Boat07Down from "./Boat07Down";
import Boat06Up from "./Boat06Up";
import Boat06Down from "./Boat06Down";
import Boat04 from "./Boat04";
import Boat05 from "./Boat05";
import Boat01 from "./Boat01";
import Boat02 from "./Boat02";
import Boat03 from "./Boat03";
import Boat08Down from "./Boat08Down";
import Boat08Up from "./Boat08Up";
import Boat10Down from "./Boat10Down";
import Boat10Up from "./Boat10Up";
import Boat11Down from "./Boat11Down";
import Boat11Up1 from "./Boat11Up1";
import Boat11Up2 from "./Boat11Up2";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ overflow:'scroll', backgroundColor:'#ffffff' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function formatDate(date: string) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [year, month, day].join('-');
}

export default function OrderChairBody() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const boatTypeId = sessionStorage.getItem('fromBoatId') || '0';
  let boatId = sessionStorage.getItem('fromId') || '0';
  let title = "Đặt vé";
  let tail = '';
  const way = sessionStorage.getItem('way') || '';
  let nextAction = '/OrderPaymentTotal';
  const page = sessionStorage.getItem('page') || '';

  if(way == 'twoway' && page.replace('/','') == 'OrderChair')
  {
    title = 'Chọn ghế chuyến về';
    nextAction = '/OrderChairTo'
  }

  if(way == 'twoway' && page.replace('/','') == 'OrderChairTo')
  {
    boatId = sessionStorage.getItem('toId') || '0';
  }

  sessionStorage.setItem('CurBoatId', boatId);
  const fetchRequest = useCallback(() => {
    setOpen(true);
    let route = sessionStorage.getItem('route') || '{}';
    const objRoute = JSON.parse(route);
    let searchDate = '';
    if(location.pathname == '/OrderChair')
    {
      searchDate = sessionStorage.getItem('fromDate') || '';
    }
    else{
      searchDate = sessionStorage.getItem('toDate') || '';
      tail = 'To';
    }
    searchDate = formatDate(searchDate);
    Service.SearchRoutes(objRoute['id'],searchDate).then(res1 => {
      Service.BoatLayout(boatId).then(res => {
          res.forEach( (element: any) => {
            const item = document.getElementById('GB'+element.name);
            if(element.published == true)
            {
              if (item) {
                item.style.backgroundColor = '#59E096';
                item.setAttribute("disabled", 'true');
              }
            }
            else{
              if (item) {
                item.classList.remove("Mui-disabled");
                item.removeAttribute("disabled");
              }
            }
            if (item) {
              item.setAttribute("ticketClass", element.ticketClass);
              item.setAttribute("ticketId", element.id);
            }
            if (sessionStorage.getItem(boatId+tail+element.name.trim()) !== null) {
              if (item) {
                item.style.backgroundColor = '#38D8EB';
              }
            }
          });
          setOpen(false);
        })
      })
  }, [])


  useEffect(() => {
    setOpen(true);
    let route = sessionStorage.getItem('route') || '{}';
    const objRoute = JSON.parse(route);
    let searchDate = '';
    if(location.pathname == '/OrderChair')
    {
      searchDate = sessionStorage.getItem('fromDate') || '';
    }
    else{
      searchDate = sessionStorage.getItem('toDate') || '';
      tail = 'To';
    }
    searchDate = formatDate(searchDate);
    setOpen(true);
    Service.SearchRoutes(objRoute['id'],searchDate).then(res1 => {
          Service.BoatLayout(boatId).then(res => {
            //console.log(res);
            res.forEach( (element: any) => {
              const item = document.getElementById('GB'+element.name);
     
              if(element.published == true)
              {
                if (item) {
                  item.style.backgroundColor = '#59E096';
                  item.setAttribute("disabled", 'true');
                }
              }
              else{
                if (item) {
                  item.classList.remove("Mui-disabled");
                  item.removeAttribute("disabled");
                }
              }
              if (item) {
                item.setAttribute("ticketClass", element.ticketClass);
                item.setAttribute("ticketId", element.id);
              }
              if (sessionStorage.getItem(boatId+tail+element.name.trim()) !== null) {
                if (item) {
                  item.style.backgroundColor = '#38D8EB';
                }
              }
            });
            
            setOpen(false);
        })
        
      })
    }, [])
              
  sessionStorage.setItem('page', location.pathname);     
  return (
    <Box sx={{ width: "100%" , backgroundColor: '#29ACE3', height: window.innerHeight - 250}}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Dưới lầu" {...a11yProps(0)} onClick={()=>fetchRequest()}/>
          {['8','11','9','7','6','10'].indexOf(boatTypeId) >= 0 ? (
          <Tab label="Trên lầu" {...a11yProps(1)} onClick={()=>fetchRequest()}/>
          ):('')}
          {boatTypeId === '11' ? (
          <Tab label="Tầng 2" {...a11yProps(2)} onClick={()=>fetchRequest()}/>
          ):('')}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {boatTypeId === '8' ? (
          <Boat08Down/>
        )
        :boatTypeId === '10' ? (
          <Boat10Down/>
        )
        :boatTypeId === '4' ? (
          <Boat04/>
        )
        :boatTypeId === '5' ? (
          <Boat05/>
        )
        :boatTypeId === '1' ? (
          <Boat01/>
        )
        :boatTypeId === '2' ? (
          <Boat02/>
        )
        :boatTypeId === '3' ? (
          <Boat03/>
        )
        :boatTypeId === '11' ? (
          <Boat11Down/>
        )
        :boatTypeId === '7' ? (
          <Boat07Down/>
        )
        :boatTypeId === '6' ? (
          <Boat06Down/>
        )
        :boatTypeId === '9' ? (
          <Boat09Down/>
        ) : (
          <Boat09Down/>
        )}
      </CustomTabPanel>
      {['8','11','9','7','6','10'].indexOf(boatTypeId) >= 0 ? (
      <CustomTabPanel value={value} index={1}>
        {boatTypeId === '8' ? (
          <Boat08Up/> 
        )
        :boatTypeId === '10' ? (
          <Boat10Up/>
        )
        :boatTypeId === '11' ? (
          <Boat11Up1/>
        )
        :boatTypeId === '7' ? (
          <Boat07Up/>
        )
        :boatTypeId === '6' ? (
          <Boat06Up/>
        )
        :boatTypeId === '9' ? (
          <Boat09Up/>
        ) : (
          <Boat09Up/>
        )}
      </CustomTabPanel>
      ):('')}
      {boatTypeId === '11' ? (
      <CustomTabPanel value={value} index={2}>
        {boatTypeId === '11' ? (
          <Boat11Up2/> 
        ) : (
          <Boat11Up2/>
        )}
      </CustomTabPanel>
      ):('')}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="section" sx={{ textAlign: 'right', paddingRight: '10px', backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        <Button startIcon={<NavigateNextIcon  />} onClick={()=>
          {
            const way = sessionStorage.getItem('way');
            const boatFromId = sessionStorage.getItem('fromId') || '0';
            let selectedAmount = sessionStorage.getItem(boatFromId+'selectedAmount') || 0;
            selectedAmount = Number(selectedAmount);
            const boatToId = sessionStorage.getItem('toId') || '0';
            let selectedAmountTo = sessionStorage.getItem(boatToId+'selectedAmountTo') || 0;
            selectedAmountTo = Number(selectedAmountTo);
            let amount = sessionStorage.getItem('amount') || 0;
            let total = selectedAmount + selectedAmountTo;

            if(way == 'twoway')
            {
              amount = Number(amount) * 2; 
            }

            if(title == "Đặt vé" && Number(amount) > total)
            {
              alert("Vui lòng chọn ghế");
            }
            else
            {
              navigate(nextAction);
            }
          }}>
          {title}
        </Button>
      </Box>
      <OrderFooter/>
    </Box>
  );
}
