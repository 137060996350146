import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Header from './Header';
import { Grid, Paper } from '@mui/material';
import HistoryBody from './HistoryBody';

export default function History() {
  return (
    <Container maxWidth="xs" sx={{ padding: 0.01, height: window.innerHeight - 26, maxHeight: '700px' }}>
      <Box sx={{ width: "100%",height:"100%" }}>
        <Grid container>
          <Grid item xs={12}>
              <Header />
          </Grid>
          <Grid item xs={12}>
              <HistoryBody />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", height: '30px', bottom: 0, backgroundColor:'#29ACE3', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
          &nbsp;
      </Box>
    </Container>
  );
}
