import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';


const BodyPage = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  }));


export default function ConditionReturnBody() {

  return (
    <Box sx={{ width: '100%' , height: '100%' , backgroundColor: '#29ACE3'}}>
      <Box sx={{padding: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff', maxHeight: 500}}>
        <BodyPage elevation={0}>
        <Typography variant="body1" gutterBottom sx={{height: window.innerHeight - 190,  overflow:'scroll', scrollbarWidth: 'none', textAlign:'justify'}}>
            <Typography variant="h6" gutterBottom>
            QUY ĐỊNH HOÀN ĐỔI VÉ
            </Typography>
            <Typography variant="body1" gutterBottom>
            Hiện tại WVIVU chưa hỗ trợ dịch vụ hủy bỏ thông tin vé đã được thanh toán thành công trên website https://wvivu.com.  Mọi vấn đề liên quan đến hủy bỏ vé quý khách vui lòng liên hệ hotline/zalo hoặc điện thoại 0975.1818.51 để hỗ trợ viên của chúng tôi hỗ trợ giải quyết.
            </Typography>
            <Typography variant="body1" gutterBottom>
            WVIVU chỉ thực hiện hoàn tiền trong trường hợp khi giao dịch, tài khoản của quý khách đã bị trừ tiền nhưng hệ thống của chúng tôi không ghi nhận việc đặt vé đó, và quý khách không nhận được vé điện tử. Khi đó, quý khách vui lòng liên hệ số điện thoại: 0975.1818.51
            </Typography>
            <Typography variant="body1" gutterBottom>
            Sau khi đã xác nhận các thông tin của khách hàng cung cấp về giao dịch không thành công, chúng tôi sẽ xử lý việc hoàn lại tiền cho Quý khách hàng trong 07-15 ngày làm việc (không tính cuối tuần và ngày lễ) bằng hình thức chuyển khoản ngân hàng.
            </Typography>
            <Typography variant="body1" gutterBottom>
            Trước khi thanh toán vé trực tuyến trên website WVIVU, chúng tôi khuyến nghị quý khách nên xác nhận lại toàn bộ thông tin đặt vé trước khi tiến hành thanh toán trực tuyến.
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;CÔNG TY TNHH TM DV QUỐC HỒ
<br></br>&nbsp;&nbsp;Mã số doanh nghiệp: 2200810541
<br></br>&nbsp;&nbsp;Địa chỉ: Số 07-09,đường 30/04, P.3,Tp Sóc Trăng.
<br></br>&nbsp;&nbsp;Điện thoại: 0975.1818.51
            </Typography>
          </Typography>
        </BodyPage>
    </Box>
  </Box>
  );
}