import { Box, Grid, ListSubheader } from '@mui/material';
import * as React from 'react';
import { Paper, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';

const FooterPage = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
}));

export default function ContactBody() {
  const navigate = useNavigate();
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: window.innerHeight - 330 }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={12} sx={{textAlign:'center'}}>
            <ListSubheader component="div" sx={{ color:'#0D11B2', fontWeight: 'bold', fontSize: '22px' }}></ListSubheader>
          </Grid>
          <Grid item xs={1.5} sx={{marginLeft: '15px', paddingTop:'15px', fontWeight: 'bold', fontSize: '28px', backgroundColor:'#F1F4F9' ,borderTopLeftRadius: 18,borderBottomLeftRadius: 18}}>
            <PinDropOutlinedIcon/>
          </Grid>
          <Grid item xs={9} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ color: 'black', paddingLeft:'1px', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px', backgroundColor:'#F1F4F9' ,borderTopRightRadius: 18,borderBottomRightRadius: 18 }}>Số 07-09 Đường 30/4, Phường 3, TP Sóc Trăng, Tỉnh Sóc Trăng</ListSubheader>
          </Grid>
          <Grid item xs={1.5} sx={{marginTop:'10px',marginLeft: '15px', paddingTop:'15px', fontWeight: 'bold', fontSize: '28px', backgroundColor:'#F1F4F9',borderTopLeftRadius: 18,borderBottomLeftRadius: 18}}>
            <PhoneInTalkOutlinedIcon/>
          </Grid>
          <Grid item xs={9} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ color: 'black', marginTop:'10px', paddingLeft:'9px', paddingTop:'5px', fontWeight: 'bold', fontSize: '28px', backgroundColor:'#F1F4F9' ,borderTopRightRadius: 18,borderBottomRightRadius: 18}}>0975.1818.51</ListSubheader>
          </Grid>
          <Grid item xs={1.5} sx={{marginLeft: '15px',marginTop:'5px', fontWeight: 'bold', fontSize: '15px', backgroundColor:'#F1F4F9',borderTopLeftRadius: 18,borderBottomLeftRadius: 18}}>
          <Box component="img" sx={{marginTop:'10px', backgroundColor:'#E6E6E6', borderRadius:18, height: '35px',width: '35px'}} src="./static/images/zalo.png"/>
          </Grid>
          <Grid item xs={9} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ color: 'black', paddingLeft:'9px', paddingTop:'5px',marginTop:'5px', fontWeight: 'bold', fontSize: '28px', backgroundColor:'#F1F4F9' ,borderTopRightRadius: 18,borderBottomRightRadius: 18}}>0975.1818.51</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box component="section" sx={{ textAlign: 'center', paddingTop:2,paddingBottom:2, backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        &nbsp;
      </Box>
      <Box sx={{ height: '158px', width: '100%' }}>
      <FooterPage sx={{textAlign:'center', height: '158px', borderBottomRightRadius: 18, borderBottomLeftRadius: 18, backgroundColor: '#29ACE3' }} elevation={0}>
          <Box sx={{textAlign:'center', fontWeight: 'bold',fontFamily: 'Times New Roman',fontSize: 25, marginTop: '5px', color: '#0D11B2'}}>
            CÁC ĐỐI TÁC
          </Box>
          <Box component="img" sx={{marginTop:'10px', backgroundColor:'#E6E6E6', borderRadius:18, height: '60px',width: '200px', padding: '5px'}} src="./static/images/PQE_Logo_Nobg-S.png"/>
      </FooterPage>
    </Box>
    </Box>
  );
}