import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Header from './Header';
import ConditionSecurityBody from './ConditionSecurityBody';
import { Grid } from '@mui/material';

export default function ConditionSecurity() {
  return (
    <Container maxWidth="xs" sx={{ padding: 0.01, height: window.innerHeight - 38, maxHeight: '690px' }}>
      <Box sx={{ width: "100%",height:"100%" }}>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
              <ConditionSecurityBody />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", height: '30px', bottom: 0, backgroundColor:'#29ACE3', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
          &nbsp;
      </Box>
    </Container>
  );
}
