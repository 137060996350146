import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Service } from './services/Service';

export default function SeachBox() {
  const [dataOptions, setDataOptions] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    Service.GetRoutes().then(res => {
          const updatedOptions = res.map((row: { name: string; id: string; }) => {
            return { label: row.name, id: row.id };
          });
          setDataOptions(updatedOptions)
      })
  }, [])
  return (
    <Paper
      component="form"
      sx={{ p: '4px 4px', display: 'flex', alignItems: 'center', width: '95%', WebkitBorderRadius: '18px', marginTop: '5px' }}
    >
      <IconButton type="button" sx={{ p: '5px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Autocomplete
              disablePortal
              blurOnSelect="touch"
              options={dataOptions}
              size="small"
              onChange={(event, newValue) => {
                sessionStorage.setItem('route', JSON.stringify(newValue));
                navigate("/OrderSearch")
              }}
              sx={{
                // border: "1px solid blue",
                "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    borderRadius: "0",
                    padding: "0"
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                },
                width: '90%'
            }}
              renderInput={(params) => <TextField inputRef={input => {

              }} {...params} name="Chọn tuyến" label="Chọn tuyến" />}
            />
    </Paper>
  );
}