import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';


const BodyPage = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  }));


export default function ConditionSecurityBody() {

  return (
    <Box sx={{ width: '100%' , height: '100%' , backgroundColor: '#29ACE3'}}>
      <Box sx={{padding: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff', maxHeight: 500}}>
        <BodyPage elevation={0}>
        <Typography variant="body1" gutterBottom sx={{height: window.innerHeight - 190,  overflow:'scroll', scrollbarWidth: 'none', textAlign:'justify'}}>
            <Typography variant="h6" gutterBottom>
            CHÍNH SÁCH BẢO MẬT THÔNG TIN
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            1. Chính sách bảo mật thông tin:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;Chính sách bảo mật này công bố cách thức mà CÔNG TY TNHH TM DV QUỐC HỒ. (Sau đây gọi tắt là “WVIVU” hoặc “Chúng tôi”) thu thập, lưu trữ và xử lý thông tin hoặc dữ liệu cá nhân (“thông tin cá nhân”) của các Khách hàng của mình thông qua website www.phuquocexpress.com
Chúng tôi cam kết sẽ bảo mật các thông tin cá nhân của Khách hàng, sẽ nỗ lực hết sức và sử dụng các biện pháp thích hợp để các thông tin mà Khách hàng cung cấp cho chúng tôi trong quá trình sử dụng website này được bảo mật và bảo vệ khỏi sự truy cập trái phép. Tuy nhiên, WVIVU không đảm bảo ngăn chặn được tất cả các truy cập trái phép.
Trong trường hợp truy cập trái phép nằm ngoài khả năng kiểm soát của chúng tôi, WVIVU sẽ không chịu trách nhiệm dưới bất kỳ hình thức nào đối với bất kỳ khiếu nại, tranh chấp hoặc thiệt hại nào phát sinh từ hoặc liên quan đến truy cập trái phép đó.
Khách hàng được khuyến nghị để nắm rõ những quyền lợi của mình khi sử dụng các dịch vụ của WVIVU được cung cấp trên website này. WVIVU đưa ra các cam kết dưới đây phù hợp với các quy định của pháp luật Việt Nam, trong đó bao gồm các cách thức mà chúng tôi sử dụng để bảo mật thông tin của Khách hàng.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            2. Mục đích thu thập thông tin cá nhân:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;WVIVU thu thập Thông tin cá nhân của Khách hàng cho một hoặc một số mục đích như sau:
            <br></br>&nbsp;&nbsp;a. Thực hiện và quản lý việc đặt chỗ và xuất vé cho Khách hàng (bao gồm cả đặt chỗ trực tuyến);
            <br></br>&nbsp;&nbsp;b. Thực hiện và quản lý việc sử dụng dịch vụ vận chuyển hàng hóa;
            <br></br>&nbsp;&nbsp;c. Thực hiện và quản lý hoạt động tiếp thị, cung cấp thông tin khuyến mại tới Khách hàng như gửi các cập nhật mới nhất về thông tin khuyến mại và chào giá mới liên quan đến sản phẩm và dịch vụ của chúng tôi;
            <br></br>&nbsp;&nbsp;d. Cung cấp giải pháp nâng cấp hoặc thay đổi dịch vụ nhằm phục vụ nhu cầu Khách hàng tốt hơn;
            <br></br>&nbsp;&nbsp;e. Quản lý, phân tích, đánh giá số liệu để xây dựng chính sách bán và chính sách phục vụ Khách hàng phù hợp;
            <br></br>&nbsp;&nbsp;f. Tiếp nhận thông tin, góp ý, đề xuất, khiếu nại của Khách hàng nhằm cải thiện chất lượng dịch vụ của chúng tôi;
            <br></br>&nbsp;&nbsp;g. Liên hệ với Khách hàng để giải quyết các yêu cầu của Khách hàng;
            <br></br>&nbsp;&nbsp;h. Đảm bảo an ninh, an toàn đường thủy và nâng cao tính an toàn đối với các giao dịch thanh toán trực tuyến.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            3. Loại thông tin thu thập
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;Những loại Thông tin cá nhân mà chúng tôi thu thập từ Khách hàng của mình bao gồm:
            <br></br>&nbsp;&nbsp;a. Thông tin cá nhân như họ và tên, ngày sinh, số chứng minh nhân dân, số hộ chiếu hoặc giấy tờ xác minh nhân thân khác;
            <br></br>&nbsp;&nbsp;b. Thông tin liên lạc như số điện thoại, địa chỉ email, số fax;
            <br></br>&nbsp;&nbsp;c. Thông tin về thanh toán nếu thanh toán bằng thẻ tín dụng hoặc thẻ ghi nợ như tên chủ thẻ, số thẻ, ngày hết hạn;
            <br></br>&nbsp;&nbsp;d. Thông tin về doanh nghiệp của Khách hàng như tên doanh nghiệp, địa chỉ doanh nghiệp, chức danh; mã số thuế.
            <br></br>&nbsp;&nbsp;e. Các thông tin khác phục vụ chương trình Khách hàng thường xuyên như địa chỉ nhà riêng, số điện thoại di động, địa chỉ email cá nhân, thói quen, sở thích và các thông tin liên quan đến các nhu cầu đặc biệt của Khách hàng.
            <br></br>&nbsp;&nbsp;f. Các Thông tin cá nhân trên được yêu cầu khác nhau đối với những dịch vụ cụ thể, bao gồm những thông tin bắt buộc phải cung cấp hoặc tùy chọn. Khách hàng có quyền từ chối hoặc không cung cấp đầy đủ các thông tin được yêu cầu. Trong trường hợp đó, WVIVU không thể cung cấp cho Khách hàng những dịch vụ đầy đủ và chất lượng.
            <br></br>&nbsp;&nbsp;g. Để có thể nhận được sự phục vụ tốt nhất, Khách hàng cần đảm bảo tính chính xác và đầy đủ của các thông tin cung cấp cho chúng tôi. Nếu có bất kỳ sự thay đổi nào về Thông tin cá nhân Khách hàng, xin vui lòng thông báo cho chúng tôi thông qua các hình thức được công bố trên website.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            4. Thông tin công ty:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;CÔNG TY TNHH TM DV QUỐC HỒ
<br></br>&nbsp;&nbsp;Mã số doanh nghiệp: 2200810541
<br></br>&nbsp;&nbsp;Địa chỉ: Số 07-09,đường 30/04, P.3,Tp Sóc Trăng.
<br></br>&nbsp;&nbsp;Điện thoại: 0975.1818.51
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            5. Phương pháp thu thập thông tin:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;WVIVU thu thập các thông tin cá nhân của khách hàng nêu tại Mục 2 nêu trên qua website thông qua dịch vụ đặt vé trực tuyến hoặc các chương trình khuyến mại cụ thể.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            6. Thời gian lưu trữ thông tin:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;WVIVU sẽ lưu trữ các Thông tin cá nhân do Khách hàng cung cấp trên các hệ thống nội bộ của chúng tôi trong quá trình cung cấp dịch vụ cho Khách hàng hoặc cho đến khi hoàn thành mục đích thu thập hoặc khi Khách hàng có yêu cầu hủy các thông tin đã cung cấp.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            7. Việc công bố thông tin:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;WVIVU có thể công bố các Thông tin cá nhân thu thập từ Khách hàng cho bên thứ ba, như các đại lý của chúng tôi, các nhà cung cấp dịch vụ khác hoặc doanh nghiệp du lịch có liên quan nhằm cung cấp dịch vụ tốt nhất cho Khách hàng.
WVIVU sẽ nỗ lực để đảm bảo rằng các nhân viên, cán bộ, đại lý, tư vấn hoặc bên thứ ba nào được đề cập ở trên có liên quan đến việc thu thập và xử lý thông tin Khách hàng sẽ nhận thức và thấu hiểu Chính sách bảo mật này.
Ngoài ra, WVIVU có thể phải cung cấp các thông tin cá nhân của Khách hàng cho các cơ quan chức năng, cơ quan của Chính phủ vì các mục đích an toàn an ninh quốc gia và các mục đích theo luật định khác trong phạm vi được yêu cầu hoặc theo luật định.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            8. Quyền của khách hàng đối với các thông tin cá nhân được thu thập:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;Bất kỳ Khách hàng nào tự nguyện cung cấp thông tin cá nhân cho WVIVU đều có các quyền như sau:
            <br></br>&nbsp;&nbsp;a. Yêu cầu xem lại các thông tin được thu thập;
            <br></br>&nbsp;&nbsp;b. Yêu cầu sao chép lại các thông tin được thu thập;
            <br></br>&nbsp;&nbsp;c. Yêu cầu chỉnh sửa, bổ sung thông tin được thu thập (trực tiếp bằng cách truy cập vào tài khoản của mình trên website của WVIVU hoặc thông qua hệ thống hỗ trợ khách hàng của chúng tôi);
            <br></br>&nbsp;&nbsp;d. Yêu cầu dừng việc thu thập thông tin;
            <br></br>&nbsp;&nbsp;e. Yêu cầu xóa các thông tin đã được thu thập.
            <br></br>&nbsp;&nbsp;f. Khách hàng có thể thực hiện các quyền trên bằng cách tự truy cập vào website hoặc liên hệ với chúng tôi qua email hoặc địa chỉ liên lạc được công bố trên website của WVIVU.
            <br></br>&nbsp;&nbsp;g. Trường hợp Khách hàng cung cấp cho WVIVU các thông tin cá nhân không chính xác hoặc không đầy đủ để xác nhận được nhân thân Khách hàng, chúng tôi không thể bảo vệ được quyền bảo mật của Khách hàng theo quy định trên.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            9. Cam kết bảo mật thông tin cá nhân của khách hàng
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;Mọi thông tin cá nhân của khách hàng thu thập được từ website WVIVU sẽ được lưu giữ an toàn; chỉ có khách hàng mới có thể truy cập vào tài khoản cá nhân của mình bằng tên đăng nhập và mật khẩu do khách hàng chọn.
Chúng tôi cam kết bảo mật thông tin, không chia sẻ, tiết lộ, chuyển giao thông tin cá nhân của khách hàng, thông tin giao dịch trực tuyến trên website WVIVU cho bất kỳ bên thứ ba nào khi chưa được sự đồng ý của khách hàng, trừ trường hợp phải thực hiện theo yêu cầu của các cơ quan Nhà nước có thẩm quyền, hoặc theo quy định của pháp luật hoặc việc cung cấp thông tin đó là cần thiết để chúng tôi cung cấp dịch vụ/ tiện ích cho khách hàng
Chúng tôi sẽ nỗ lực áp dụng các giải pháp công nghệ để ngăn chặn các hành vi đánh cắp hoặc tiếp cận thông tin trái phép; sử dụng, thay đổi hoặc phá hủy thông tin trái phép. Tuy nhiên, chúng tôi không thể cam kết sẽ ngăn chặn được tất cả các hành vi xâm phạm, sử dụng thông tin cá nhân trái phép nằm ngoài khả năng kiểm soát. WVIVU sẽ không chịu trách nhiệm dưới bất kỳ hình thức nào đối với bất kỳ khiếu nại, tranh chấp hoặc thiệt hại nào phát sinh từ hoặc liên quan đến việc truy cập, xâm nhập, sử dụng thông tin trái phép như vậy.
Trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân, gây ảnh hưởng xấu đến khách hàng, chúng tôi sẽ ngay lập tức thông báo cho khách hàng và trình vụ việc cho cơ quan chức năng điều tra xử lý.
Đối với các giao dịch trực tuyến được thực hiện thông qua Website WVIVU, Chúng tôi không lưu trữ thông tin thẻ thanh toán của khách hàng. Thông tin tài khoản, thẻ thanh toán của khách hàng sẽ được các đối tác cổng thanh toán của WVIVU bảo vệ theo tiêu chuẩn quốc tế.
Khách hàng có nghĩa vụ bảo mật tên đăng ký, mật khẩu và hộp thư điện tử của mình. Chúng tôi sẽ không chịu trách nhiệm dưới bất kỳ hình thức nào đối với các thiệt hại, tổn thất (nếu có) do khách hàng không tuân thủ quy định bảo mật này.
Khách hàng tuyệt đối không được có các hành vi sử dụng công cụ, chương trình để can thiệp trái phép vào hệ thống hay làm thay dổi dữ liệu của WVIVU. Trong trường hợp chúng tôi phát hiện khách hàng có hành vi cố tình giả mạo, gian lận, phát tán thông tin cá nhân trái phép … Chúng tôi có quyền chuyển thông tin cá nhân của khách hàng cho các cơ quan có thẩm quyền để xử lý theo quy định của pháp luật.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            10 .Việc cập nhật và ngôn ngữ của chính sách bảo mật:
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;WVIVU sẽ chỉnh sửa Chính sách bảo mật này vào bất kỳ thời điểm nào khi cần thiết, bản Chính sách bảo mật cập nhật sẽ được công bố trên website của chúng tôi.
Theo quy định pháp luật, ngôn ngữ được ưu tiên sử dụng và tham chiếu là tiếng Việt. Trong trường hợp có sự mâu thuẫn trong cách giải thích giữa bản tiếng Việt và các ngôn ngữ khác thì bản tiếng Việt sẽ được ưu tiên tham chiếu.
            </Typography>
          </Typography>
        </BodyPage>
    </Box>
  </Box>
  );
}