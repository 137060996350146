import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';


const BodyPage = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  }));


export default function ConditionTransferBody() {

  return (
    <Box sx={{ width: '100%' , height: '100%' , backgroundColor: '#29ACE3'}}>
      <Box sx={{padding: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff', maxHeight: 500}}>
        <BodyPage elevation={0}>
        <Typography variant="body1" gutterBottom sx={{height: window.innerHeight - 190,  overflow:'scroll', scrollbarWidth: 'none', textAlign:'justify'}}>
            <Typography variant="h6" gutterBottom>
            CHÍNH SÁCH GIAO NHẬN VÉ
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            Cách nhận vé tàu cao tốc:
            </Typography>
            <Typography variant="body1" gutterBottom>
            Tiện lợi và phổ biển nhất: Sau khi xác nhận thanh toán và nhận đầy đủ thông tin hành khách, Wvivu sẽ gửi Quý khách vé điện tử bằng file pdf vé tàu qua mục "Đơn hàng" ở giao diện Trang Chủ. Quý khách in ra giấy hoặc mở vé bằng điện thoại, nhân viên kiểm soát vé chỉ cần quét mã vạch trên vé là quý khách có thể lên tàu.
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;CÔNG TY TNHH TM DV QUỐC HỒ
<br></br>&nbsp;&nbsp;Mã số doanh nghiệp: 2200810541
<br></br>&nbsp;&nbsp;Địa chỉ: Số 07-09,đường 30/04, P.3,Tp Sóc Trăng.
<br></br>&nbsp;&nbsp;Điện thoại: 0975.1818.51
            </Typography>
          </Typography>
        </BodyPage>
    </Box>
  </Box>
  );
}