import * as React from 'react';
import Typography from '@mui/material/Typography';
import AppsIcon from '@mui/icons-material/Apps';
import Grid from "@mui/material/Unstable_Grid2";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import SubMenu from './SubMenu';

export default function HomeHeader() {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  var titleHeader = "ĐẶT VÉ";
  if(location.pathname == '/Contact')
  {
    titleHeader = "LIÊN HỆ";
  }
  if(location.pathname == '/History')
  {
    titleHeader = "ĐƠN HÀNG";
  }
  if(location.pathname == '/Price')
  {
    titleHeader = "GIÁ VÉ";
  }
  if(location.pathname == '/ConditionReturn' || location.pathname == '/ConditionSecurity' || location.pathname == '/ConditionTransfer')
  {
    titleHeader = "ĐIỀU KHOẢN";
  }
  let action = "-1";
  if(location.pathname == '/OrderPaymentTotal')
  {
    action = "/";
  }

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const navigate = useNavigate();

  return (
    <div>
        <Typography sx={{  height: '150px', borderTopLeftRadius: 18, borderTopRightRadius: 18, textAlign: 'center',background: `linear-gradient(9deg, rgba(67,183,230,1) 0%, rgba(67,183,230,1) 40%, rgba(164,220,245,1) 68%);`}}>
          <Grid container>
            <Grid sx={{paddingTop: 2,paddingLeft: 2}}>
              <IconButton sx={{ color: 'white',backgroundColor: 'grey'}} onClick={toggleDrawer(true)}>
                <AppsIcon/>
              </IconButton>
            </Grid>
            <Grid xs={8.2} sx={{height: '70px'}}>
              <Box component="img" sx={{height: '90px',width: '90px', marginTop: '8px'}} src="./static/images/logo.png"/>
            </Grid>
            <Grid sx={{paddingTop: 2,paddingRight: 2}}>
              <IconButton sx={{ color: 'white', backgroundColor: 'grey'}} onClick={()=>{action == '-1' ? navigate(-1) : navigate(action)}}>
                <ArrowBackIcon/>
              </IconButton>
            </Grid>
          </Grid>
          <Box alignItems="center" sx={{fontWeight: 'bold',fontFamily: 'Times New Roman',fontSize: 36,marginTop: '20px', color: '#fff'}}>
            {titleHeader}
          </Box>
        </Typography>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <SubMenu/>
        </Drawer>
    </div>
  );
}

