import { Backdrop, Box, Button, CircularProgress, Grid, InputLabel, ListSubheader } from '@mui/material';
import * as React from 'react';
import OrderFooter from './OrderFooter'
import { useNavigate } from 'react-router-dom';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
//import { Service } from './services/Service';
import { useEffect, useState } from 'react';

// function recall(status: string) {
//   if(status != '1') window.location.reload();
// }

export default function OrderZaloCallbackBody() {
  //const [statusData, setStatusData] = useState("");
  const [statusText, setStatusText] = useState("");
  const [statusImage, setStatusImage] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let status = '-1';
  let status_text = '-1';
  let image_path = "./static/images/check.png";
  //setStatusImage(image_path);
  let apptransid = '';
  if(urlParams.has('apptransid'))
  {
    apptransid = urlParams.get('apptransid') || '';
  }
  if(urlParams.has('status'))
  {
    status = urlParams.get('status') || '';
    //setStatusData(status);
  }
  const navigate = useNavigate();
  
  const [open, setOpen] = React.useState(false);
  if(status == '1')
  {
    status_text = 'thành công';
    //setStatusText(status_text);
    //setStatusImage(image_path);
  }

  if(status == '2' || Number(status)  < 0)
  {
    status_text = 'không thành công';
    //setStatusText(status_text);
    image_path = "./static/images/close.png";
    //setStatusImage(image_path);
  }

  if(status == '3')
  {
    status_text = 'đang xử lý';
    //setStatusText(status_text);
    image_path = "./static/images/process.png";
    //setStatusImage(image_path);
  }

  //useEffect(() => {
    // Service.CheckZaloStatus(queryString).then(res => {
    //   console.log(res);
    //   if(res)
    //   {
    //     setStatusData(res.data.return_code);
    //     if(res.data.return_code == '1')
    //     {
    //       status_text = 'thành công';
    //       setStatusText(status_text);
    //     }
      
    //     if(res.data.return_code != '1' || Number(res.data.return_code)  < 0 || res.data.return_code != '3')
    //     {
    //       status_text = 'không thành công';
    //       setStatusText(status_text);
    //       image_path = "./static/images/close.png";
    //       setStatusImage(image_path);
    //     }
      
    //     if(res.data.return_code == '3')
    //     {
    //       status_text = 'đang xử lý';
    //       setStatusText(status_text);
    //       image_path = "./static/images/process.png";
    //       setStatusImage(image_path);
    //       setTimeout(recall, 10000);
    //     }
    //   }
    //})
  //}, [])  
  
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <LocalAtmOutlinedIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>CHỌN HÌNH THỨC THANH TOÁN</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box 
      component="form"
      sx={{'& .MuiTextField-root': { m: 1 }, backgroundColor:'#ffffff' }}
      noValidate 
      autoComplete="off"
      >
        <div>
        <Grid container>
            <Grid item xs={11} sx={{textAlign:'left', height: window.innerHeight - 400, maxHeight: '480px'}}>
              <Grid container>
                <Grid item xs={11} sx={{textAlign:'center'}}>
                <Box component="img" sx={{height: '50px',width: '50px'}} src={image_path}/>
                </Grid>
                <Grid item xs={11} sx={{textAlign:'center'}}>
                  <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px',marginBottom: '10px', fontWeight: 'bold'}}>Thanh toán {status_text}</InputLabel> 
                </Grid>
                <Grid item xs={11} sx={{textAlign:'center'}}>
                  <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px'}}><Button variant="contained" onClick={()=>navigate("/History")}>Chi tiết Đơn hàng</Button></InputLabel> 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="section" sx={{ textAlign: 'center', paddingTop:2,paddingBottom:2, backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
      </Box>
      <OrderFooter/>
    </Box>
  );
}