import { Box, Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListSubheader, OutlinedInput } from '@mui/material';
import * as React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import OrderFooter from './OrderFooter'
import MailIcon from '@mui/icons-material/Mail';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

function check(id: string, value: string) {
  const item = document.getElementById(id);
  if(value.trim() == '')
  {
    return false;
  }
  else
  {
    return true;
  }
}

function validate(id: string, value: string) {
  const item = document.getElementById(id);
  if(value.trim() == '')
  {
    if (item) {
      item.style.display = 'block';
    }
    return false;
  }
  else
  {
    if (item) {
      item.style.display = 'none';
    }
    return true;
  }
}

export default function OrderBillBody() {
  const navigate = useNavigate();
  let CustomerBillName = localStorage.getItem('CustomerBillName') == null ? '' : localStorage.getItem('CustomerBillName');
  let CustomerTax = localStorage.getItem('CustomerTax') == null ? '' : localStorage.getItem('CustomerTax');
  let CustomerCompany = localStorage.getItem('CustomerCompany') == null ? '' : localStorage.getItem('CustomerCompany');
  let CustomerAddress = localStorage.getItem('CustomerAddress') == null ? '' : localStorage.getItem('CustomerAddress');
  sessionStorage.setItem('page','OrderBill');
  let CheckCustomerBillName = CustomerBillName != '' ? true: false;
  let CheckCustomerTax = CustomerTax != '' ? true : false;
  let CheckCustomerCompany = CustomerCompany != '' ? true : false;
  let CheckCustomerAddress = CustomerAddress != '' ? true : false;
  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <PersonIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>THÔNG TIN HÓA ĐƠN (NẾU CÓ)</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        backgroundColor:'#ffffff'
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Người mua</InputLabel>
          <OutlinedInput
            defaultValue={CustomerBillName}
            onChange={e => 
              {
                CheckCustomerBillName = check('CustomerBillName',e.target.value.toString());
                localStorage.setItem('CustomerBillName', e.target.value);
              }
            }
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"><PersonIcon/></InputAdornment>}
            label="Người mua (Nếu có)"
          />
          <FormHelperText id="CustomerBillName" error sx={{display:'none'}}>Vui lòng nhập "Họ tên người mua"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Mã số thuế</InputLabel>
          <OutlinedInput
            defaultValue={CustomerTax}
            onChange={e => 
              {
                CheckCustomerTax = validate('CustomerTax',e.target.value.toString());
                localStorage.setItem('CustomerTax', e.target.value);
              }
            }
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"><MailIcon/></InputAdornment>}
            label="Mã số thuế"
          />
          <FormHelperText id="CustomerTax" error sx={{display:'none'}}>Vui lòng nhập "Mã số thuế"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Tên công ty</InputLabel>
          <OutlinedInput
            defaultValue={CustomerCompany}
            onChange={e => 
              {
                CheckCustomerCompany = validate('CustomerCompany',e.target.value.toString());
                localStorage.setItem('CustomerCompany', e.target.value);
              }
            }
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"><MailIcon/></InputAdornment>}
            label="Tên công ty"
          />
          <FormHelperText id="CustomerCompany" error sx={{display:'none'}}>Vui lòng nhập "Tên công ty"</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ width: '95%',margin:'10px' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Địa chỉ</InputLabel>
          <OutlinedInput
            defaultValue={CustomerAddress}
            onChange={e => 
              {
                CheckCustomerAddress = validate('CustomerAddress',e.target.value.toString());
                localStorage.setItem('CustomerAddress', e.target.value);
              }
            }
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start"><MailIcon/></InputAdornment>}
            label="Địa chỉ"
          />
          <FormHelperText id="CustomerAddress" error sx={{display:'none'}}>Vui lòng nhập "Địa chỉ"</FormHelperText>
        </FormControl>
        <Box component="section" sx={{ textAlign: 'right', paddingRight: '10px' }}>
        
        </Box>
      </div>
      </Box>
      <Box component="section" sx={{ textAlign: 'right', paddingRight: '10px', backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        <Button startIcon={<NavigateNextIcon  />} onClick={()=>
          {
            if(CheckCustomerBillName)
            {
              if(!CheckCustomerTax)
              {
                const item = document.getElementById('CustomerTax');
                if (item) {
                  item.style.display = 'block';
                }
              }
              if(!CheckCustomerAddress)
              {
                const item = document.getElementById('CustomerAddress');
                if (item) {
                  item.style.display = 'block';
                }
              }
              if(!CheckCustomerCompany)
              {
                const item = document.getElementById('CustomerCompany');
                if (item) {
                  item.style.display = 'block';
                }
              }
            }
            if((CheckCustomerCompany && CheckCustomerTax && CheckCustomerAddress && CheckCustomerBillName) || (!CheckCustomerCompany && !CheckCustomerTax && !CheckCustomerAddress && !CheckCustomerBillName))
            {
              navigate("/OrderPassenger");
            }
          }
        }>
          Tiếp theo
        </Button>
      </Box>
      <OrderFooter/>
    </Box>
  );
}