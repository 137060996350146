import { Box, Button, ButtonGroup, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import {Service} from './services/Service';

export default function Boat06Up() {
  const [open, setOpen] = React.useState(false);
  
  let component1 = [];
  for(var i = 1;i<11;i++) {
    component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBA0'+i : 'GBA'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>A{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBB0'+i : 'GBB'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>B{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBC0'+i : 'GBC'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>C{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
  }

  let component2 = [];
  for(var i = 2;i<11;i++) {
    component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBE0'+i : 'GBE'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>E{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBF0'+i : 'GBF'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>F{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBG0'+i : 'GBG'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>G{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
  }

  let component3 = [];
  for(var i = 1;i<11;i++) {
    component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={i.toString().length == 1 ? 'GBK0'+i : 'GBK'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>K{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBL0'+i : 'GBL'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>L{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={i.toString().length == 1 ? 'GBM0'+i : 'GBM'+i} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>M{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
  }

  return (
    <Grid container sx={{ width:'700px' }}>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '650px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '3px' }}>
          <ButtonGroup variant="outlined" sx={{margin: '2px'}}><Button sx={{backgroundColor: 'yellow', marginRight:'1px'}}>Ghế VIP</Button>,<Button sx={{marginRight:'1px'}}>Ghế trống</Button>,<Button sx={{backgroundColor: '#59E096', color: 'white'}}>Ghế đã xuất vé</Button>,<Button sx={{backgroundColor: '#38D8EB', color: '#F6FAFD'}}>Ghế đang chọn</Button></ButtonGroup>
        </Box> 
      </Grid>
      <Grid item sx={{ textAlign:'center' , width: '210px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '35px' }}>
          {component1}
        </Box> 
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '210px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '70px', marginBottom: '20px' }}>
          {component2}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component3}
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        message="Bạn đã chọn đủ ghế, Vui lòng thực hiện bước tiếp theo!"
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
}

