import { Box, Button, ButtonGroup, Grid, Snackbar } from '@mui/material';
import * as React from 'react';
import {Service} from './services/Service';

export default function Boat11Up1() {
  const [open, setOpen] = React.useState(false);

  let component1 = [];
  for(var i = 2; i< 37; i++) {
    if(i  < 4)
    {
      component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button><Button id={'GBEB'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EB{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEC'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EC{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBED'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>ED{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
    else if(i >= 17 && i <= 19)
    {
      component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
    }
    else
    {
      component1.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBEA'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>EA{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEB'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EB{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEC'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EC{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBED'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>ED{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
    }
  }

  let component2 = [];
  for(var i = 1; i < 37; i++) {
      if(i < 3)
      {
        component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBEE'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>EE{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEF'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EF{i.toString().length == 1 ? '0'+i : i}</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else if(i >= 19 && i <= 21)
      {
        component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component2.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBEE'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>EE{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEF'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EF{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEG'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EG{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
  }

  let component3 = [];
  for(var i = 1;i<37;i++) {
      if(i < 3)
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button id={'GBEI'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>EI{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEJ'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EJ{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
      else if(i >= 19 && i <= 21)
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component3.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBEH'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>EH{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEI'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EI{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEJ'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EJ{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
  }

  let component4 = [];
  for(var i = 2;i<35;i++) {
      if(i < 4)
      {
        component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBEK'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>EK{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEL'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EL{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEM'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EM{i.toString().length == 1 ? '0'+i : i}</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else if(i == 19)
      {
        component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>,<Button disabled>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button></ButtonGroup>);
      }
      else
      {
        component4.push(<ButtonGroup variant="outlined" sx={{margin: '1px'}}><Button id={'GBEK'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) } }>EK{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEL'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EL{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEM'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EM{i.toString().length == 1 ? '0'+i : i}</Button>,<Button id={'GBEN'+(i.toString().length == 1 ? '0'+i : i)} onClick={ (e) =>  { Service.processSelected(e.currentTarget) ? setOpen(true) : setOpen(false) }  }>EN{i.toString().length == 1 ? '0'+i : i}</Button></ButtonGroup>);
      }
  }

  return (
    <Grid container sx={{ width:'1120px' }}>
      <Grid item  xs={10}sx={{ textAlign:'center' , width: '900px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '3px' }}>
          <ButtonGroup variant="outlined" sx={{margin: '2px'}}><Button sx={{backgroundColor: 'yellow', marginRight:'1px'}}>Ghế VIP</Button>,<Button sx={{marginRight:'1px'}}>Ghế trống</Button>,<Button sx={{backgroundColor: '#59E096', color: 'white'}}>Ghế đã xuất vé</Button>,<Button sx={{backgroundColor: '#38D8EB', color: '#F6FAFD'}}>Ghế đang chọn</Button></ButtonGroup>
        </Box> 
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '55px' }}>
          {component1}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component2}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '20px', marginBottom: '20px' }}>
          {component3}
        </Box>
      </Grid>
      <Grid item  sx={{ textAlign:'center' , width: '280px'}}>
        <Box sx={{display: "flex",flexDirection: "column",alignItems: "center", marginTop: '55px', marginBottom: '20px' }}>
          {component4}
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        message="Bạn đã chọn đủ ghế, Vui lòng thực hiện bước tiếp theo!"
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
}